<template>
    <div class="wrapper">
       <div class="logo">
           <img src="..\assets\img\logo有色备份@2x.png">
       </div>
       <div class="wrapper__form1">
         <div class="phone">
           <img src="..\assets\img\手机号.png">
         </div>
         <div class="wrapper__input1">
            <input class="wrapper__input1__content"
              placeholder="请输入手机号"
              type="text"
              v-model="data.phoneNumber"
              maxlength="11"
            >
         </div>
       </div>
       <div class="form1_solid"></div>
       <div class="wrapper__form2">
         <div class="lock">
           <img src="..\assets\img\开锁换锁.png">
         </div>
         <div class="wrapper__input2">
            <input class="wrapper__input2__content"
              placeholder="请输入短信验证码"
              type="text"
              v-model="data.code"
              maxlength="6"
            >
         </div>
         <div class="getver" @click="send" :disabled="disabled">{{text}}</div>
       </div>
       <div class="form2_solid"></div>
       <div class="wrapper-active">
         <div class="check" @click="handleAutoLogin">
           <img :src=showImg>
         </div>
         <div class="active-content">下次自动登录</div>
         <div class="active-account" @click="select('login')">账号密码登录</div>
       </div>
        <div class="wrapper__button" @click="handleSubmit"
        :style="{'backgroundColor': buttonType ? '#3974FB' : '#A7BFF5'}"
        >登录</div>
        <div class="wrapper__register" @click="select('read')">新用户注册</div>
    </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { postForm } from '../service/base'
import { useRouter } from 'vue-router'
import selectButton from '../components/getCode'

export default {
  name: 'verification',
  setup () {
    const { text, disabled, countTime } = selectButton()
    const router = useRouter()
    const data = reactive({ phoneNumber: '', code: '' })
    const showCheck = ref(true)

    // 页面跳转
    const select = (a) => { router.push(a) }

    // 按钮勾选样式
    const showImg = computed(() => {
      return !showCheck.value ? require('@/assets/img/选中.png') : require('@/assets/img/未选中.png')
    })

    // 定义按钮颜色样式
    const buttonType = computed(() => {
      return !!(data.phoneNumber.length === 11 && data.code.length === 6 && disabled.value)
    })

    // 发送验证码
    const send = async () => {
      try {
        var formData = new FormData()
        formData.append('phoneNumber', data.phoneNumber)
        formData.append('key', 'a5b4c18cc837456092804e400b5019c9')
        await postForm('/home/system/send_verify', formData)
        if (data.phoneNumber) {
          if ((/^1[3|4|5|8][0-9]\d{4,8}$/.test(data.phoneNumber))) {
            alert('验证码发送成功')
            countTime()
          } else {
            alert('手机号不正确')
          }
        } else {
          alert('手机号不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }

    // 登录
    const handleSubmit = async () => {
      try {
        var formData = new FormData()
        formData.append('account', data.phoneNumber)
        formData.append('verify', data.code)
        formData.append('loginType', 1)
        const result = await postForm('/home/freeuser/login', formData)
        if (data.phoneNumber && data.code) {
          if (result.data.code === 200) {
            alert(result.data.message)
            select('/')
          } else {
            alert(result.data.message)
          }
        } else {
          alert('必填项不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }

    return {
      data,
      text,
      disabled,
      showImg,
      send,
      handleSubmit,
      select,
      buttonType
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.logo {
  text-align: center;
  width: 1.5rem;
  margin: 0.4rem auto 0.65rem auto;
}
img {
  width: 100%
}
.wrapper__form1 {
  display: flex;
  line-height: 0.22rem;
  margin: 0 0.2375rem 0 0.2375rem;
  .phone {
  margin-top: 0.05rem;
  width: 0.1192rem;
  }
  .wrapper__input1 {
    margin-left: 0.12rem;
    flex: 1;
    box-sizing: border-box;
    .wrapper__input1__content {
      font-size: 0.16rem;
      line-height: 0.24rem;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
}
.wrapper__form2 {
  display: flex;
  line-height: 0.24rem;
  margin: 0 0.24rem 0 0.2375rem;
  .lock {
  margin-top: 0.02rem;
  width: 0.115rem;
  }
  .wrapper__input2 {
    margin-left: 0.12rem;
    flex: 1;
    box-sizing: border-box;
    .wrapper__input2__content {
      font-size: 0.16rem;
      line-height: 0.24rem;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
  .getver {
    width: 0.73rem;
    height: 0.24rem;
    border-radius: 0.02rem;
    border: 0.005rem solid rgba(30, 30, 30, 1);
    text-align: center;
    box-sizing: border-box;
  }
}
.form1_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.12rem 0 0.13rem 0.2375rem;
}
.form2_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.14rem 0 0.13rem 0.2rem;
}
.wrapper-active {
  display: flex;
  align-items: center;
  margin-left: 0.24rem;
  .check {
    padding-right: 0.15rem;
    width: 0.16rem;
    padding-top: 0.03rem;
  }
  .active-content {
    font-size: 0.16rem;
    font-family: PingFangSC-Regular;
    color: #333333;
    margin-right: 1.22rem;
  }
  .active-account {
    font-size: 0.13rem;
    color: #3974FB;
  }
}
.wrapper__button {
  line-height: 0.4rem;
  margin: 0.29rem 0.2rem 0.16rem 0.2rem;
  border-radius: 0.04rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #ffffff;
}
.wrapper__register{
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFangSC-Regular;
  color: #3974FB;
}
  ::-webkit-input-placeholder {
      color: #CFCFCF
    }

</style>
